import { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';
import { ModelData } from 'bloko/common/tree/types';

import fetcher from 'lux/modules/fetcher';

const INDUSTRY_URL = '/shards/industry';

declare global {
    interface FetcherGetApi {
        [INDUSTRY_URL]: {
            queryParams: {
                lang: string;
                site: string;
                useParentId?: boolean;
            };
            response: { items: ModelData[] };
        };
    }
}

const industriesTreeAction = makeSetStoreField('industriesTree');

type TreeCacheType = Record<string, ModelData[]>;

export default function useFetchIndustryTree(useParentId = false): void {
    const industryTreeCache = useRef<TreeCacheType>({});
    const dispatch = useDispatch();
    const lang = useContext(TranslationLangContext);

    useEffect(() => {
        if (!(lang in industryTreeCache.current)) {
            fetcher
                .get(INDUSTRY_URL, {
                    params: { lang, site: window.globalVars.siteId, useParentId: useParentId || undefined },
                })
                .then((tree) => {
                    industryTreeCache.current[lang] = tree.items;
                    dispatch(industriesTreeAction(tree.items));
                }, console.error);
        } else {
            dispatch(industriesTreeAction(industryTreeCache.current[lang]));
        }
    }, [lang, dispatch, useParentId]);
}
