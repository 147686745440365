import { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'bloko/blocks/button';
import { TrashScaleSmall, CameraScaleSmall, CameraScaleMedium, IconColor } from 'bloko/blocks/icon';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text from 'bloko/blocks/text';
import { formatNewLine } from 'bloko/common/trl';

import {
    employerLogoFileTooLarge,
    employerLogoUnsupportedFileFormat,
} from 'lux/components/Notifications/EmployerSidebar';
import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';

const LogoEdit = ({
    invertDefaultText,
    logoUrl,
    logoOnLoad,
    logoOnDelete,
    loading,
    employerLogoEditSettings,
    trls,
}) => {
    const { addNotification } = useNotification();

    const onLoad = useCallback(
        (event) => {
            if (loading) {
                return;
            }
            const imageFile = event.target ? event.target.files[0] : event[0];

            if (event.target) {
                event.target.value = null;
            }

            if (imageFile.size > employerLogoEditSettings.maxSizeBytes) {
                addNotification(employerLogoFileTooLarge);
                return;
            }

            if (!employerLogoEditSettings.allowedMimeTypes.includes(imageFile.type)) {
                addNotification(employerLogoUnsupportedFileFormat);
                return;
            }

            logoOnLoad?.(imageFile, addNotification, employerLogoEditSettings);
        },
        [loading, logoOnLoad, employerLogoEditSettings, addNotification]
    );

    const onDelete = useCallback(() => {
        logoOnDelete?.(addNotification);
    }, [addNotification, logoOnDelete]);

    const renderInput = useCallback(
        (getInputProps) => (
            <input
                {...getInputProps()}
                type="file"
                className="g-hidden"
                accept={employerLogoEditSettings.allowedMimeTypes.join()}
                onChange={onLoad}
                data-qa="sidebar-upload-logo"
            />
        ),
        [onLoad, employerLogoEditSettings]
    );

    const renderImageDropZone = useCallback(
        ({ getRootProps, getInputProps, isDragActive }) => {
            if (!logoUrl) {
                return (
                    <div
                        {...getRootProps()}
                        className={classnames('employer-sidebar-logo-load employer-sidebar-logo-load_no-image', {
                            'employer-sidebar-logo-load_drop': isDragActive,
                        })}
                    >
                        <div className="employer-sidebar-logo-load__icon">
                            {loading ? (
                                <Loading data-qa="logo-loader" scale={LoadingScale.Medium} />
                            ) : (
                                <CameraScaleMedium initial={invertDefaultText ? IconColor.White : IconColor.Gray50} />
                            )}
                        </div>
                        <div className="employer-sidebar-logo-load__add-link">
                            <Link appearance={LinkAppearance.Pseudo}>
                                {trls[LogoEdit.trls.addLogo]}
                                {renderInput(getInputProps)}
                            </Link>
                        </div>
                        <div
                            className={classnames('employer-sidebar-logo-load__description', {
                                'employer-sidebar-logo-load__description_inverted': invertDefaultText,
                            })}
                        >
                            <Text>{formatNewLine(trls[LogoEdit.trls.logoParams])}</Text>
                        </div>
                    </div>
                );
            }
            return (
                <div
                    {...getRootProps()}
                    className={classnames('employer-sidebar-logo-load', {
                        'employer-sidebar-logo-load_drop': isDragActive,
                    })}
                >
                    <div className="employer-sidebar-logo-load__button">
                        <label>
                            <Button Element="span" icon={<CameraScaleSmall />} />
                            {renderInput(getInputProps)}
                        </label>
                    </div>
                    <div className="employer-sidebar-logo-load__button">
                        <Button onClick={onDelete} icon={<TrashScaleSmall />} />
                    </div>
                    {loading && (
                        <div className="employer-sidebar-logo-load__icon employer-sidebar-logo-load__icon_container">
                            <Loading scale={LoadingScale.Medium} />
                        </div>
                    )}
                </div>
            );
        },
        [invertDefaultText, loading, logoUrl, onDelete, renderInput, trls]
    );

    return (
        <Dropzone multiple={false} disableClick={!!logoUrl} onDrop={onLoad}>
            {renderImageDropZone}
        </Dropzone>
    );
};

LogoEdit.trls = {
    addLogo: 'employer.edit.sidebar.addLogo',
    logoParams: 'employer.edit.sidebar.logoParams',
};

LogoEdit.propTypes = {
    trls: PropTypes.object,

    invertDefaultText: PropTypes.bool,

    logoUrl: PropTypes.string,
    loading: PropTypes.bool,
    logoOnLoad: PropTypes.func,
    logoOnDelete: PropTypes.func,
    employerLogoEditSettings: PropTypes.shape({
        maxSizeBytes: PropTypes.number,
        allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
        maxWidth: PropTypes.number,
        maxHeight: PropTypes.number,
    }),
};

export default translation(LogoEdit);
