import classnames from 'classnames';
import PropTypes from 'prop-types';

import Link, { LinkAppearance } from 'bloko/blocks/link';

import translation from 'lux/components/translation';

const COLORS = [
    'e6b8af',
    'f4cccd',
    'fce5cd',
    'fff2cc',
    'd9ead3',
    'cfe0e3',
    'c9daf8',
    'cfe2f3',
    'd9d2e9',
    'ead1dc',
    'dd7e6b',
    'ea9999',
    'f9ca9c',
    'ffe599',
    'b6d7a8',
    'a2c4ca',
    'a4c2f4',
    '9fc5e7',
    'b4a7d6',
    'd5a6bd',
    'cc4125',
    'e06666',
    'f6b26a',
    'ffd966',
    '93c47d',
    '76a5af',
    '6d9eeb',
    '6fa8dc',
    '8e7cc3',
    'c27ba0',
    'a61c00',
    'cc0000',
    'e69037',
    'f1c232',
    '6aa84f',
    '44818e',
    '3d78d8',
    '3d85c6',
    '674fa7',
    'a64d79',
    '85200c',
    '990001',
    'b45f06',
    'bf9000',
    '38761e',
    '144f5c',
    '1255cc',
    '0a5393',
    '341c75',
    '741b47',
    '5b0f00',
    '660000',
    '783f05',
    '7f6000',
    '264f13',
    '0d333d',
    '1c4487',
    '073763',
    '20134d',
    '4c1330',
];

const ADDITIONAL_COLORS = [
    '000000',
    '434343',
    '666666',
    '999999',
    'b7b7b7',
    'cccccc',
    'd9d9d9',
    'efefef',
    'f3f3f3',
    'ffffff',
];

const ColorPickerPalette = ({ selected, defaultColor, setColor, trls }) => {
    const colorItem = (color) => (
        <div
            key={color}
            className={classnames('color-picker__color', {
                'color-picker__color_selected': color === selected,
            })}
            data-qa={`color-${color}`}
            style={{ backgroundColor: `#${color}` }}
            onClick={() => setColor(color)}
        />
    );
    return (
        <>
            <div className="color-picker">{COLORS.map(colorItem)}</div>
            <div className="color-picker color-picker_additional">{ADDITIONAL_COLORS.map(colorItem)}</div>
            <Link
                appearance={LinkAppearance.Pseudo}
                onClick={() => setColor(defaultColor)}
                data-qa="text-default-color"
            >
                {trls[ColorPickerPalette.trls.default]}
            </Link>
        </>
    );
};

ColorPickerPalette.propTypes = {
    selected: PropTypes.string.isRequired,
    defaultColor: PropTypes.string.isRequired,
    setColor: PropTypes.func.isRequired,
    trls: PropTypes.object,
};

ColorPickerPalette.trls = {
    default: 'colorPicker.defaultColor',
};

export default translation(ColorPickerPalette);
