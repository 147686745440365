import {
    employerLogoUploadError,
    employerLogoUploadSuccess,
    employerLogoUploadTimeout,
} from 'lux/components/Notifications/EmployerSidebar';
import { setLogoStatus, setLogoUrl, Status } from 'lux/models/employer/employerLogo';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const CHECK_TIMEOUT_MS = 200;
const MAX_CHECK_ATTEMPTS = 20;

const uploadLogoCheckStatus =
    (addNotification, employerLogoEditSettings, attempts = MAX_CHECK_ATTEMPTS) =>
    (dispatch) => {
        dispatch(setLogoStatus(Status.Fetching));
        return fetcher.get(`/employer/edit/logo?t=${Date.now()}`).then(
            (data) => {
                if (data.state === 'GENERATED') {
                    dispatch([setLogoUrl(data.url), setLogoStatus(Status.Success)]);
                    addNotification(employerLogoUploadSuccess);
                } else if (data.state === 'FAILED') {
                    dispatch(setLogoStatus(Status.Fail));
                    addNotification(employerLogoUploadError, {
                        props: {
                            logoMaxWidth: employerLogoEditSettings.maxWidth,
                            logoMaxHeight: employerLogoEditSettings.maxHeight,
                        },
                    });
                } else if (attempts < 0) {
                    dispatch(setLogoStatus(Status.Fail));
                    addNotification(employerLogoUploadTimeout);
                } else {
                    setTimeout(() => {
                        dispatch(uploadLogoCheckStatus(addNotification, employerLogoEditSettings, attempts - 1));
                    }, CHECK_TIMEOUT_MS);
                }
            },
            (error) => {
                dispatch(setLogoStatus(Status.Fail));
                defaultRequestErrorHandler(error, addNotification);
            }
        );
    };

export default uploadLogoCheckStatus;
