import { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import { FormItem, FormLegend } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import { H1 } from 'bloko/blocks/header';
import InputText, { InputType } from 'bloko/blocks/inputText';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import throttle from 'bloko/common/throttle';

import Sidebar from 'lux/components/Employer/Sidebar';
import Form from 'lux/components/Form';
import { useNotification } from 'lux/components/Notifications/Provider';
import PageLayout, { EmptyLayout } from 'lux/components/PageLayout';
import translation from 'lux/components/translation';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { setSite } from 'lux/models/employer/employerInfo';
import fetcher from 'lux/modules/fetcher';

import ColorPicker from 'lux/pages/EmployerSidebarEdit/components/ColorPicker';
import {
    employerSaveSidebarError,
    employerSaveSidebarSuccess,
} from 'lux/pages/EmployerSidebarEdit/components/Notifications';
import SelectIndustries from 'lux/pages/EmployerSidebarEdit/components/SelectIndustries';

const checkUrl = throttle(async ({ url, setSiteIsInvalid, setSite, setLastCheckedUrl }) => {
    if (!url) {
        setLastCheckedUrl(url);
        setSiteIsInvalid(false);
        setSite();
        return;
    }
    let result;
    try {
        result = await fetcher.postFormData('/employer/edit/check_url', { url });
    } catch (error) {} // eslint-disable-line no-empty
    setLastCheckedUrl(url);
    if (!result?.data?.href) {
        setSiteIsInvalid(true);
        return;
    }
    setSite(result.data);
    setSiteIsInvalid(false);
}, 500);

const EmployerSidebarEdit = ({ canEdit, employerName, colors, site, setSite, trls }) => {
    const [fetching, setFetching] = useState(false);
    const [siteIsInvalid, setSiteIsInvalid] = useState(false);
    const [lastCheckedUrl, setLastCheckedUrl] = useState(site);
    const isZarplata = useIsZarplataPlatform();
    const siteRef = useRef(null);
    const form = useRef(null);
    const { addNotification } = useNotification();

    const save = useCallback(
        async (event) => {
            event.preventDefault();
            if (fetching || siteIsInvalid) {
                return;
            }
            setFetching(true);
            try {
                await fetcher.postFormData('/employer/edit/sidebar', new FormData(form.current));
                addNotification(employerSaveSidebarSuccess);
            } catch (error) {
                addNotification(employerSaveSidebarError);
            }
            setFetching(false);
        },
        [addNotification, fetching, siteIsInvalid]
    );

    return (
        <PageLayout title={trls[EmployerSidebarEdit.trls.title]} layout={EmptyLayout}>
            <div className="employer-constructor-xs-hidden">
                <Gap bottom />
            </div>
            <ColumnsWrapper>
                <Column xs="4" s="8" m="3" l="3">
                    <Sidebar editMode={canEdit} hideEditSidebarButton={true} />
                </Column>
                <Column xs="4" s="8" m="9" l="11" container>
                    <Column xs="4" s="8" m="9" l="11">
                        <div className="company-header">
                            <Text
                                Element={Link}
                                size={TextSize.Small}
                                importance={TextImportance.Tertiary}
                                to="/employer/edit/simple"
                            >
                                {trls[EmployerSidebarEdit.trls.company]} {`\u00ab${employerName}\u00bb`}
                            </Text>
                            <H1>{trls[EmployerSidebarEdit.trls.header]}</H1>
                            <VSpacing base={4} />
                        </div>
                    </Column>
                    <Gap bottom />
                    <Form onSubmit={save} ref={form}>
                        <Gap bottom>
                            <Column xs="4" s="4" m="3" l="3">
                                <FormLegend>{trls[EmployerSidebarEdit.trls.site]}</FormLegend>
                            </Column>
                            <Column xs="4" s="8" m="6" l="8">
                                <FormItem>
                                    <InputText
                                        defaultValue={site}
                                        invalid={siteIsInvalid && lastCheckedUrl === siteRef.current.value}
                                        ref={siteRef}
                                        onChange={(value) => {
                                            checkUrl({
                                                url: value,
                                                setSiteIsInvalid,
                                                setSite,
                                                setLastCheckedUrl,
                                            });
                                        }}
                                        type={InputType.Text}
                                        name="url"
                                        data-qa="input-company-site"
                                    />
                                </FormItem>
                            </Column>
                        </Gap>
                        <Gap bottom>
                            <SelectIndustries />
                        </Gap>
                        {!isZarplata && (
                            <>
                                <ColorPicker color={colors.background} defaultColor="f9f9f9" name="background" />
                                <ColorPicker color={colors.header} defaultColor="333333" name="header" />
                                <ColorPicker color={colors.text} defaultColor="9fa3aa" name="text" />
                                <ColorPicker color={colors.link} defaultColor="0099ff" name="link" />
                            </>
                        )}

                        <Column xs="0" s="0" m="3" l="3" />
                        <Column xs="4" s="8" m="4" l="4">
                            <Button
                                type={ButtonType.Submit}
                                kind={ButtonKind.Primary}
                                data-qa="save-company-description-button"
                                disabled={fetching || siteIsInvalid}
                            >
                                {trls[EmployerSidebarEdit.trls.save]}
                            </Button>
                        </Column>
                    </Form>
                </Column>
            </ColumnsWrapper>
        </PageLayout>
    );
};

EmployerSidebarEdit.propTypes = {
    canEdit: PropTypes.bool,
    /** Название компании */
    employerName: PropTypes.string,
    /** Цвета сайдбара */
    colors: PropTypes.shape({
        background: PropTypes.string,
        header: PropTypes.string,
        link: PropTypes.string,
        text: PropTypes.string,
    }),
    /** Ссылка на сайт */
    site: PropTypes.string,
    setSite: PropTypes.func.isRequired,
    trls: PropTypes.object,
};

EmployerSidebarEdit.trls = {
    title: 'employer.edit.sidebar.title',
    company: 'employer.edit.sidebar.company',
    header: 'employer.edit.sidebar.h1',
    site: 'sidebar.company.employer.link',
    save: 'Save',
};

export default connect(
    ({ employerInfo, employerConstructor }) => ({
        canEdit: employerInfo.canEditDescription,
        employerName: employerInfo.name,
        site: employerInfo.site?.href,
        colors: employerConstructor.sidebarColors,
    }),
    { setSite }
)(translation(EmployerSidebarEdit));
