import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import ClickDown, { DownPlacement } from 'bloko/blocks/drop/Down/Click';
import { FormLegend, FormItem } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';

import translation from 'lux/components/translation';
import { setSidebarColor } from 'lux/models/employerConstructor';

import ColorPickerPalette from 'lux/pages/EmployerSidebarEdit/components/ColorPickerPalette';

const ColorPicker = ({ color, defaultColor, name, trls }) => {
    const selected = color || defaultColor;
    const dispatch = useDispatch();
    const [active, setActive] = useState(false);
    const setColor = useCallback(
        (color) => {
            dispatch(setSidebarColor({ name, color }));
        },
        [dispatch, name]
    );
    return (
        <Column xs="4" s="2" m="9" l="11" container>
            <Gap bottom>
                <Column xs="4" s="2" m="3" l="3">
                    <FormLegend>{trls[ColorPicker.trls[name]]}</FormLegend>
                    <input type="hidden" value={selected} name={name} />
                </Column>
                <Column xs="4" s="2" m="1" l="1">
                    <ClickDown
                        render={() => (
                            <ColorPickerPalette selected={selected} defaultColor={defaultColor} setColor={setColor} />
                        )}
                        onClose={() => setActive(false)}
                        placement={DownPlacement.RightStart}
                    >
                        <FormItem>
                            <div
                                className={classnames('color-picker-trigger', {
                                    'color-picker-trigger_selected': active,
                                })}
                                data-qa={`color-picker-${name}Color`}
                                style={{ backgroundColor: `#${selected}` }}
                                onClick={() => setActive(true)}
                            />
                        </FormItem>
                    </ClickDown>
                </Column>
            </Gap>
        </Column>
    );
};

ColorPicker.propTypes = {
    color: PropTypes.string,
    defaultColor: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    trls: PropTypes.object,
};

ColorPicker.trls = {
    background: 'employer.page.sidebar.color.background',
    header: 'employer.page.sidebar.color.header',
    link: 'employer.page.sidebar.color.link',
    text: 'employer.page.sidebar.color.text',
};

export default translation(ColorPicker);
