import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    successText: 'employer.page.sidebar.success',
    errorText: 'employer.page.sidebar.error',
};

const SaveSidebarSuccess: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.successText]}</>;
export const employerSaveSidebarSuccess = {
    Element: translation(SaveSidebarSuccess),
    kind: 'ok',
    autoClose: true,
};

const SaveSidebarError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.errorText]}</>;
export const employerSaveSidebarError = {
    Element: translation(SaveSidebarError),
    kind: 'error',
    autoClose: true,
};
