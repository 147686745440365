import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Checkbox from 'bloko/blocks/checkbox';
import Column from 'bloko/blocks/column';
import CompositeSelection from 'bloko/blocks/compositeSelection';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import { FormLegend, FormItem } from 'bloko/blocks/form';
import { QuestionMarkScaleSmall, IconColor } from 'bloko/blocks/icon';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';
import { TreeModel } from 'bloko/common/tree/types';

import translation from 'lux/components/translation';
import useFetchIndustryTree from 'lux/hooks/useFetchIndustryTree';
import { CountryCode } from 'lux/models/countryId';
import { EmployerCategory, Industries, setIndustries } from 'lux/models/employer/employerInfo';
import { EmployerState } from 'lux/models/employerStatus';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

import styles from './select-industries.less';

const Features = {
    accreditedITEmployerEnabled: 'enable_accredited_it_employer',
};

const TrlKeys = {
    scope: 'employer.page.scope',
    addIndustry: 'employer.page.sidebar.editIndustries.addLink',
    editIndustry: 'employer.page.sidebar.editIndustries.editLink',
    submit: 'treeselector.save',
    cancel: 'treeselector.cancel',
    searchPlaceholder: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
    accreditedITEmployer: 'employer.page.sidebar.accreditedITEmployer',
    itAccreditationBlocked: 'employer.page.sidebar.itAccreditationBlocked',
};

const IT_INDUSTRY_ID = 7;
const isItEmployer = (rubrics: Industries[]) => !!rubrics?.find((item) => item.id === IT_INDUSTRY_ID);

const SelectIndustries: TranslatedComponent = ({ trls }) => {
    const accreditedITEmployerEnabled = useSelector(
        ({ employerInfo, features }) =>
            features[Features.accreditedITEmployerEnabled] && employerInfo.employerCountryCode === CountryCode.Russia
    );
    const {
        industries,
        itAccreditationBlocked,
        accreditedITEmployer,
        category: employerCategory,
    } = useSelector(({ employerInfo }) => employerInfo);
    const employerState = useSelector(({ employerStatus }) => employerStatus.state);
    const industriesTree = useSelector((state) => state.industriesTree);
    const [isAccreditedITEmployer, setAccreditedITEmployer] = useState(!!accreditedITEmployer);
    const isIT = industries?.some((item) => item.id === IT_INDUSTRY_ID);
    const [shouldShowAccreditedITEmployer, setShouldShowAccreditedITEmployer] = useState(
        !!accreditedITEmployer || isIT
    );
    useFetchIndustryTree();
    const dispatch = useDispatch();

    const industriesTreeCollection = useMemo(() => fromTree(industriesTree), [industriesTree]);
    const onProfareaChange = useCallback(
        (changes: Array<string>) => {
            const newIndustries = changes.reduce((result: Industries[], id) => {
                const currentID = parseInt(id, 10);
                const item = industriesTreeCollection.getParent(id) as unknown as TreeModel<string>;
                const itemID = parseInt(item.id, 10);
                const currentIndustry = result.find((industry) => industry.id === itemID);
                if (!currentIndustry) {
                    result.push({
                        id: itemID,
                        trl: item.text,
                        items: [currentID],
                    });
                } else {
                    currentIndustry.items.push(currentID);
                }
                return result;
            }, []);

            dispatch(setIndustries(newIndustries));

            if (!isItEmployer(newIndustries)) {
                setAccreditedITEmployer(false);
                setShouldShowAccreditedITEmployer(false);
            } else {
                setShouldShowAccreditedITEmployer(true);
            }
        },
        [dispatch, industriesTreeCollection]
    );

    const handleChangeAccreditedITEmployer = useCallback(() => {
        setAccreditedITEmployer(!isAccreditedITEmployer);

        if (!isItEmployer(industries)) {
            setShouldShowAccreditedITEmployer(false);
        }
    }, [isAccreditedITEmployer, setAccreditedITEmployer, industries, setShouldShowAccreditedITEmployer]);

    return (
        <>
            <Column xs="4" s="4" m="3" l="3">
                <FormLegend>{trls[TrlKeys.scope]}</FormLegend>
            </Column>
            <Column xs="4" s="8" m="6" l="8">
                <>
                    <FormItem> </FormItem>
                    <FormItem text>
                        {!!industriesTree.length && (
                            <CompositeSelection
                                collection={industriesTreeCollection}
                                maxItems={5}
                                value={industries
                                    .map(({ items }) => items)
                                    .flat()
                                    .map(String)}
                                onChange={onProfareaChange}
                                leavesOnly={true}
                                trl={{
                                    submit: trls[TrlKeys.submit],
                                    cancel: trls[TrlKeys.cancel],
                                    searchPlaceholder: trls[TrlKeys.searchPlaceholder],
                                    notFound: trls[TrlKeys.notFound],
                                }}
                            >
                                {({ renderTagList, showTreeSelectorPopup }) => {
                                    const renderNestedTag = ({
                                        item: { text, id },
                                    }: {
                                        item: TreeModel<{ text: string; id: number }>;
                                    }) => (
                                        <>
                                            {text}
                                            <input type="hidden" name="industryId" value={id} />
                                        </>
                                    );
                                    return (
                                        <>
                                            <Link
                                                appearance={LinkAppearance.Pseudo}
                                                onClick={showTreeSelectorPopup}
                                                data-qa="industry-tree-link"
                                            >
                                                {industries?.length
                                                    ? trls[TrlKeys.editIndustry]
                                                    : trls[TrlKeys.addIndustry]}
                                            </Link>
                                            {renderTagList({
                                                nested: true,
                                                renderNestedTag,
                                            })}
                                        </>
                                    );
                                }}
                            </CompositeSelection>
                        )}
                        {!industriesTree.length && <Loading scale={LoadingScale.Small} />}
                    </FormItem>
                    {accreditedITEmployerEnabled &&
                        employerCategory === EmployerCategory.Company &&
                        employerState === EmployerState.Approved && (
                            <div
                                className={classnames(styles.accreditationContainer, {
                                    [styles.accreditationContainerInvisible]: !shouldShowAccreditedITEmployer,
                                })}
                            >
                                <FormItem>
                                    <input
                                        type="hidden"
                                        name="accreditedITEmployer"
                                        value={isAccreditedITEmployer.toString()}
                                    />
                                    <Checkbox
                                        checked={isAccreditedITEmployer}
                                        onChange={handleChangeAccreditedITEmployer}
                                        disabled={itAccreditationBlocked}
                                    >
                                        {trls[TrlKeys.accreditedITEmployer]}
                                    </Checkbox>
                                    {itAccreditationBlocked && (
                                        <>
                                            {NON_BREAKING_SPACE}
                                            <HoverTip flexible render={() => trls[TrlKeys.itAccreditationBlocked]}>
                                                <QuestionMarkScaleSmall initial={IconColor.Gray60} />
                                            </HoverTip>
                                        </>
                                    )}
                                </FormItem>
                            </div>
                        )}
                </>
            </Column>
        </>
    );
};

export default translation(SelectIndustries);
